<template>
	<div class="mainBox">
		<img :src="banner" style="width: 100%;height: auto;" />
		<div class="formBox" v-if="cardLog.length>0">
			<van-steps direction="vertical" :active="0">
				<van-step v-for="(item,index) in cardLog">
					<h3>{{item.content}}</h3>
					<p>{{item.time}}</p>
				</van-step>
			</van-steps>
			<div style="margin: 16px;">
				<van-button round type="primary" native-type="submit" color="#000000" @click="back()">
					返回详情
				</van-button>
			</div>
		</div>
		<div class="formBox" v-else>
			<div style="padding: 80px 50px;">
				<van-loading size="24px" type="spinner" v-if="!loading" vertical>数据加载中</van-loading>
			</div>
			<van-empty image="search" description="暂无订单进度数据" v-if="loading" />
		</div>
		<div class="footer">&copy;Sanmark<div class="istero" @click="toistero">起零公司提供技术支持</div>
		</div>
	</div>
</template>
<script>
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import {
		showToast,
		showDialog
	} from 'vant';
	import {
		showConfirmDialog
	} from 'vant';

	import {
		areaList
	} from '@vant/area-data';

	import {
		getBanner,
		getOrderDetail,
		getCardProgress
	} from '@/api/home';
	export default {
		name: 'App',
		data() {
			return {
				banner: "",
				show: false,
				loading: false,
				cardDetail: "",
				cardLog: ""
			}
		},
		created() {
			let that = this
			that.sn = that.$route.query.sn
			that.getOrderDetail()
			that.getCardProgress()
			that.getBanner()
		},
		setup() {
			return {

			};

		},
		methods: {
			getBanner() {
				let that = this
				getBanner({}).then(res => {
					if (res.code == 200) {
						that.banner = res.data.zb_img
					}
				})
			},
			back() {
				let that = this
				that.$router.back()
			},


			formattedTime(date) {
				const year = date.getFullYear();
				const month = ("0" + (date.getMonth() + 1)).slice(-2);
				const day = ("0" + date.getDate()).slice(-2);
				const hour = ("0" + date.getHours()).slice(-2);
				const minute = ("0" + date.getMinutes()).slice(-2);
				const second = ("0" + date.getSeconds()).slice(-2);
				return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
			},
			getOrderDetail() {
				let that = this
				getOrderDetail({
					sn: that.sn,
				}).then(res => {
					if (res.code == 200) {
						that.cardDetail = res.data
						that.loading = true
					}

				})
			},
			getCardProgress() {
				let that = this
				getCardProgress({
					sn: that.sn,
				}).then(res => {
					if (res.code == 200) {
						that.cardLog = res.data
						that.loading = true
					}

				})
			},
		}


	}
</script>
<style>
	body {
		background: rgb(24, 24, 24);
	}

	.mainBox {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}



	.mainBox .formBox {
		width: 85%;
		padding: 15px 5px 15px 5px;
		text-align: left;
		background: #ffffff;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		font-size: 13px;
		border-radius: 10px;
	}

	.mainBox .title {
		font-size: 14px;
		padding: 15px 0px;
	}

	.mainBox .footer {
		font-size: 12px;
		padding: 30px 0px;
		color: #676767;
		text-align: center;
	}

	.mainBox .footer .istero {
		font-size: 11px;
		border-bottom: #4c4c4c solid 1px;
		margin-top: 5px;
		width: fit-content;
		margin-left: auto;
		margin-right: auto;
	}

	.van-step--vertical {
		padding: 5px 10px 10px 0 !important;
		line-height: 10px;
	}

	.van-step__title h3 {
		font-size: 13px !important;
		line-height: 18px;
		margin-bottom: -9px;

	}
</style>